import React from 'react';
import { QrThemeProvider, useTheme } from './theme/useTheme';
import { Routes, Route } from 'react-router-dom';
import { GlobalStyle } from './styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { QrDataDefinition } from './pages/QrDataDefinition';

export const App = () => {
  return (
    <QrThemeProvider>
      <InnerApp />
    </QrThemeProvider>
  );
};

const InnerApp = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route element={<QrDataDefinition />} path='/' />
      </Routes>
    </ThemeProvider>
  );
};

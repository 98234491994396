import {
  useCallback,
  useState,
  createContext,
  useContext,
  FC,
  ReactNode,
} from 'react';
import { dark, light, ThemeType } from './themes';

const THEME_STORAGE_KEY: string = 'SELECTED_THEME';

const getCurrentTheme = () => {
  // TODO: Whenever we activate dark theme, uncomment this
  // const currentTheme = localStorage.getItem(THEME_STORAGE_KEY);
  // if (!currentTheme) {
  //   return light;
  // }
  // return currentTheme === 'light' ? light : dark;
  return light;
};

const ThemeContext = createContext<{
  theme: ThemeType;
  toggleTheme: () => void;
}>({
  theme: getCurrentTheme() || light,
  toggleTheme: () => undefined,
});

export const useTheme = () => {
  const context = useContext(ThemeContext);

  const { theme } = context;

  return {
    theme,
  };
};

export const QrThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState(getCurrentTheme());

  const toggleTheme = useCallback(() => {
    const { name } = theme;
    const isLight = name === 'light';
    setTheme(isLight ? dark : light);
    localStorage.setItem(THEME_STORAGE_KEY, isLight ? 'dark' : 'light');
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {' '}
      {children}{' '}
    </ThemeContext.Provider>
  );
};

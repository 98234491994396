const common = {};

export const light = {
  name: 'light',
  background: 'white',
  ...common,
};

export const dark = {
  name: 'dark',
  background: 'navy',
  ...common,
};

export type ThemeType = {
  name: string;
  background: string;
};

export const deskData = [
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_1',
    text: 'Sløyden #1',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_2',
    text: 'Sløyden #2',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_3',
    text: 'Sløyden #3',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_4',
    text: 'Sløyden #4',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_5',
    text: 'Sløyden #5',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_6',
    text: 'Sløyden #6',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_7',
    text: 'Sløyden #7',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_8',
    text: 'Sløyden #8',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_9',
    text: 'Sløyden #9',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_10',
    text: 'Sløyden #10',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_11',
    text: 'Sløyden #11',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_12',
    text: 'Sløyden #12',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=sloyden_desk_13',
    text: 'Sløyden #13',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=innerst_desk_1',
    text: 'Innerst #1',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=innerst_desk_2',
    text: 'Innerst #2',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=innerst_desk_3',
    text: 'Innerst #3',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=innerst_desk_4',
    text: 'Innerst #4',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=innerst_desk_5',
    text: 'Innerst #5',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=innerst_desk_6',
    text: 'Innerst #6',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=salgshjornet_desk_1',
    text: 'Salgshjørnet #1',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=salgshjornet_desk_2',
    text: 'Salgshjørnet #2',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=salgshjornet_desk_3',
    text: 'Salgshjørnet #3',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=salgshjornet_desk_4',
    text: 'Salgshjørnet #4',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=salgshjornet_desk_5',
    text: 'Salgshjørnet #5',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=salgshjornet_desk_6',
    text: 'Salgshjørnet #6',
  },
  {
    value:
      'https//meet.iterate.no/desk/quick-book?deskId=prosjektrommet_desk_1',
    text: 'Prosjekrommet',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=arbeidsrom_desk_1',
    text: 'Arbeidsrom #1',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=arbeidsrom_desk_2',
    text: 'Arbeidsrom #2',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=arbeidsrom_desk_3',
    text: 'Arbeidsrom #3',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=arbeidsrom_desk_4',
    text: 'Arbeidsrom #4',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=arbeidsrom_desk_5',
    text: 'Arbeidsrom #5',
  },
  {
    value: 'https//meet.iterate.no/desk/quick-book?deskId=arbeidsrom_desk_6',
    text: 'Arbeidsrom #6',
  },
];

import React, { FC } from 'react';
import { QrCode } from '../components/QrCode';
type GeneratedQrCodesType = {
  qrCodes: {
    value: string;
    text: string;
  }[];
  size?: number;
  backgroundColor?: string;
  foregroundColor?: string;
};

export const GeneratedQrCodes: FC<GeneratedQrCodesType> = ({
  qrCodes,
  size,
  backgroundColor,
  foregroundColor,
}) => {
  return (
    <div className='qr-code-wrapper'>
      {qrCodes.map(({ value, text }, index) => {
        if (value === '') {
          return null;
        }
        return (
          <div className='qr-code' key={index} style={{ marginBottom: 20 }}>
            <QrCode
              value={value}
              text={text}
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
              size={size}
            />
          </div>
        );
      })}
    </div>
  );
};

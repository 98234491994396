import React, { FC } from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

type QrCodeType = {
  value: string;
  text: string;
  size?: number;
  backgroundColor?: string;
  foregroundColor?: string;
};

export const QrCode: FC<QrCodeType> = ({
  value,
  size = 300,
  text,
  backgroundColor,
  foregroundColor,
}) => {
  return (
    <div className='qr-code'>
      <QRCode
        value={value}
        size={size}
        bgColor={backgroundColor}
        fgColor={foregroundColor}
      />
      {!!text && (
        <StyledText backgroundColor={backgroundColor} color={foregroundColor}>
          {text}
        </StyledText>
      )}
    </div>
  );
};

const StyledText = styled.div<{ backgroundColor?: string; color?: string }>`
  display: flex;
  justify-content: center;
  margin: 20px auto;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#000000'};
  color: ${({ color }) => color || '#ffffff'};
  font-weight: 700;
  font-size: 16px;
`;

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { deskData } from '../data/deskData';
import { GeneratedQrCodes } from './GeneratedQrCodes';

type QrCodeType = {
  value: string;
  text: string;
};

export const QrDataDefinition: FC = () => {
  const [qrCodes, setQrCodes] = useState<QrCodeType[]>([
    { value: '', text: '' },
  ]);
  const [size, setSize] = useState<number>(300);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [foregroundColor, setForegroundColor] = useState('#ffffff');
  const [generated, setGenerated] = useState<boolean>(false);

  const addQrCode = () => {
    setQrCodes([...qrCodes, { value: '', text: '' }]);
  };
  const updateQrCodeValue = (index: number, value: string) => {
    const qrCodesCopy = [...qrCodes];
    qrCodesCopy[index].value = value;
    setQrCodes(qrCodesCopy);
  };
  const updateQrCodeText = (index: number, text: string) => {
    const qrCodesCopy = [...qrCodes];
    qrCodesCopy[index].text = text;
    setQrCodes(qrCodesCopy);
  };
  const clearQrCode = (index: number) => {
    const qrCodesCopy = [...qrCodes];
    qrCodesCopy.splice(index, 1);
    setQrCodes(qrCodesCopy);
  };
  return (
    <StyledPageWrapper>
      <div className='no-print'>
        <StyledPanel>
          {qrCodes.map(({ value, text }, index) => (
            <div
              key={index}
              style={{
                display: 'grid',
                margin: '20px auto',
                gridTemplateColumns: '1fr 1fr 45px',
                gridGap: '20px',
              }}
            >
              <StyledInput
                type='text'
                value={value}
                placeholder={`QR value ${index + 1}`}
                onChange={({ target }) =>
                  updateQrCodeValue(index, target.value)
                }
              />
              <StyledInput
                type='text'
                value={text}
                placeholder={`QR text ${index + 1}`}
                onChange={({ target }) => updateQrCodeText(index, target.value)}
              />
              <button onClick={() => clearQrCode(index)}>X</button>
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <button onClick={addQrCode}>Add</button>
          </div>
        </StyledPanel>
        <StyledPanel>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
              gridGap: '20px',
            }}
          >
            <StyledInput
              onChange={({ target }) => setSize(Number(target.value))}
              placeholder='Size'
              value={size}
              type='number'
            />
            <StyledInput
              onChange={({ target }) => setBackgroundColor(target.value)}
              value={backgroundColor}
              type='color'
            />
            <StyledInput
              onChange={({ target }) => setForegroundColor(target.value)}
              value={foregroundColor}
              type='color'
            />
            <button
              disabled={qrCodes[0].value === ''}
              onClick={() => setGenerated(true)}
            >
              Generate
            </button>
            <button
              disabled={!generated}
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
          </div>
        </StyledPanel>
        <StyledPanel
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridGap: '20px',
          }}
        >
          <button onClick={() => setQrCodes(deskData)}>Add Desk Codes</button>
        </StyledPanel>
      </div>
      {generated && (
        <StyledQrCodesWrapper className='print'>
          <GeneratedQrCodes
            qrCodes={qrCodes}
            size={size}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
          />
        </StyledQrCodesWrapper>
      )}
    </StyledPageWrapper>
  );
};

const StyledPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  @media print {
    .print {
      color-adjust: exact;
      -webkit-print-color-adjust: exact;
      display: flex;
      justify-content: space-around;
    }
    .qr-code-wrapper {
      break-inside: avoid;
      .qr-code {
        display: inline-block;
        margin-right: 15px;
      }
    }
    .no-print {
      display: none;
    }
  }
`;

const StyledPanel = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  margin: 5px;
  width: 60vw;
`;

const StyledInput = styled.input`
  width: 100%;
`;

const StyledQrCodesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin: 10px 20px;
  width: 100vw;
`;
